import React from "react"
import About from "./about.js"

//import bootstrap styles
import "../bootstrap/css/bootstrap.min.css"

//import my custom styles
import "./index.css"

export default () => (
  <div className="App">
   <About/>
  </div>
)
